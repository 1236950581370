<template>
  <svg
    viewBox="0 0 410 293"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M187.854 154.758V134.654H240.056V154.758H187.854Z" />
    <path
      d="M120.867 209.109C105.583 209.109 93.4706 204.838 84.5313 196.296C75.592 187.754 71.1263 175.558 71.134 159.709V130.087C71.134 114.215 75.5998 102.015 84.5313 93.4887C93.4629 84.9622 105.575 80.6912 120.867 80.6757C136.035 80.6757 147.763 84.8186 156.049 93.1043C164.336 101.39 168.48 112.763 168.48 127.222V128.282H145.553V126.523C145.553 119.231 143.526 113.236 139.472 108.538C135.418 103.84 129.204 101.487 120.832 101.479C112.592 101.479 106.126 104.007 101.435 109.062C96.7443 114.118 94.3909 120.994 94.3754 129.691V159.977C94.3754 168.558 96.7287 175.407 101.435 180.524C106.142 185.642 112.607 188.197 120.832 188.189C129.173 188.189 135.387 185.809 139.472 181.049C143.557 176.288 145.584 170.324 145.553 163.157V159.977H168.48V162.446C168.48 176.906 164.336 188.278 156.049 196.564C147.763 204.85 136.035 209.032 120.867 209.109Z"
    />
    <path
      d="M263.717 206.64V83.1684H287.017V185.44H343.46V206.605L263.717 206.64Z"
    />
    <path
      d="M409.2 292.848H0V0H409.2V292.848ZM22.4259 270.414H386.774V22.4229H22.4259V270.414Z"
    />
  </svg>
</template>

<script>
export default {

}
</script>
