<template>
  <svg
    width="124"
    height="97"
    viewBox="0 0 124 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M37.4832 48.3347H120.795"
        stroke="#4AF47D"
        stroke-width="3.68619"
        stroke-miterlimit="10"
      />
      <path
        d="M107.666 35.2051L120.795 48.3345L107.666 61.464"
        stroke="#4AF47D"
        stroke-width="3.68619"
        stroke-miterlimit="10"
      />
      <path
        d="M48.3347 94.8261C74.0113 94.8261 94.8262 74.0111 94.8262 48.3346C94.8262 22.658 74.0113 1.84302 48.3347 1.84302C22.6581 1.84302 1.84314 22.658 1.84314 48.3346C1.84314 74.0111 22.6581 94.8261 48.3347 94.8261Z"
        stroke="#4AF47D"
        stroke-width="3.68619"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="123.405" height="96.69" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
</style>