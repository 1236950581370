<template>
  <section class="fullpage bgx bg-cover">
    <div class="w-full h-full">
      <div class="w-1/2 h-full bg-gradient-to-r from-gray-500">
        <div class="px-20 py-40">
          <div class="flex flex-row">
            <div
              class="
                bg-cl-1
                text-white
                h-10
                p-4
                py-6
                flex
                items-center
                font-cl font-bold
                text-2xl
              "
            >
              Mário Amorim Lopes
            </div>
          </div>
          <div
            class="
              text-7xl
              uppercase
              text-white
              font-black font-cl
              mt-10
              leading-snug
            "
          >
            Blockchain:<br />
            Sejamos Honestos
          </div>
          <div class="font-cl text-white text-lg font-semibold">
            These computers that makeup Bitcoin's network are called nodes. In
            this model, Bitcoin's blockchain is used in a decentralized way.
            However, private, centralized blockchains, where the computers that
            make up its network.
          </div>
          <div class="flex flex-row mt-10 items-center">
            <div><IconButton class="h-20"></IconButton></div>
            <div class="font-cl text-white text-2xl font-semibold ml-4">
              Explore o programa
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconButton from '../components/IconButton.vue'
export default {
  components: { IconButton }

}
</script>

<style scoped>
.bgx {
  background-image: url("~@/assets/original 2.png");
}
</style>