<template>
  <div class="w-72 py-6">
    <div class="bg-white shadow-xl rounded-3xl overflow-hidden">
      <div class="bg-cover bg-center h-56 p-4 bg-gray-200">
        <div class="flex justify-end"></div>
      </div>
      <div class="p-6">
        <p class="text-3xl text-black font-cl font-black h-20 flex items-end">
          {{ fields.name }}
        </p>
        <p class="text-gray-700 font-cl mt-2 tracking-tight">
          {{ fields.description }}
        </p>
        <div class="flex items-center pt-2">
          <IconButton class="w-14"></IconButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from './IconButton.vue'
export default {
  components: { IconButton },
  props: ['fields']

}
</script>

<style>
</style>