<template>
  <header class="h-20 top-0 z-50">
    <div class="flex flex-row px-10 py-4 items-center">
      <div>
        <Logo class="text-cl-1 h-12"></Logo>
      </div>
      <div
        class="
          flex flex-row
          font-cl
          text-lg
          font-semibold
          text-gray-400
          flex-grow
          justify-end
          gap-x-10
          px-20
        "
      >
        <div
          class="
            cursor-pointer
            hover:text-cl-1
            outline-none
            hover:border-cl-1
            border-transparent border-b-2
            hover:border-current
          "
        >
          Projeto
        </div>
        <div
          class="
            cursor-pointer
            hover:text-cl-1
            outline-none
            hover:border-cl-1
            border-transparent border-b-2
            hover:border-current
          "
        >
          Pricing
        </div>
        <div
          class="
            cursor-pointer
            hover:text-cl-1
            outline-none
            hover:border-cl-1
            border-transparent border-b-2
            hover:border-current
          "
        >
          Diretório
        </div>
        <div
          class="
            cursor-pointer
            hover:text-cl-1
            outline-none
            hover:border-cl-1
            border-transparent border-b-2
            hover:border-current
          "
        >
          Speakers
        </div>
      </div>
      <div class="flex gap-x-4">
        <Button2 class="h-10">User Login</Button2>
        <Button class="h-10">Get in touch</Button>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from './Logo.vue'
import Button from './Button.vue'
import Button2 from './Button2.vue'
export default {
  components: { Logo, Button, Button2 }

}
</script>

<style>
</style>