<template>
  <div id="app">
    <Header></Header>
    <!--
    <header class="flex h-20 sticky top-0 z-50" :class="headerClass">
      <div class="p-4 text-3xl font-bold">
        <a @click="scrollToSection(0)" class="cursor-pointer">
          <Logo class="text-cl-1 w-20"></Logo>
        </a>
      </div>
      <nav
        class="md:ml-auto flex flex-wrap items-center text-base justify-center"
      >
        <a
          class="mr-5 hover:text-gray-900 cursor-pointer"
          @click="scrollToSection(1)"
          >About</a
        >
        <a
          class="mr-5 hover:text-gray-900 cursor-pointer"
          @click="scrollToSection(2)"
          >Services</a
        >
        <a
          class="mr-5 hover:text-gray-900 cursor-pointer"
          @click="scrollToSection(3)"
          >Team</a
        >
      </nav>
    </header>
    <div class="sections-menu">
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      >
      </span>
    </div>
    -->
    <Destaque></Destaque>
    <Cursos></Cursos>
    <FullPageCurso></FullPageCurso>
    <Scroll v-show="false"></Scroll>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Destaque from './pages/Destaque.vue'
import Cursos from './pages/Cursos.vue'
import FullPageCurso from './pages/FullPageCurso.vue'
import Scroll from './pages/Scroll.vue'
import Footer from './components/Footer.vue'
export default {
  components: { Header, Destaque, Cursos, FullPageCurso, Scroll, Footer },
  name: 'App',
  setup () {




    return {}
  },

}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@100;400;600;700;800;900&display=swap");

.font-cl {
  font-family: "Space Grotesk", sans-serif;
}

.fullpage {
  height: 100vh;
  width: 100%;
}
</style>
