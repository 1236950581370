<template>
  <section class="fullpage">
    <div class="flex flex-row pt-10">
      <div
        class="
          w-2/5
          font-cl
          flex flex-col
          py-10
          px-20
          text-6xl
          font-bold
          text-cl-1
        "
      >
        <div>C-LEARNIN FOR</div>
        <div>C-LEVEL EXPERTS</div>
      </div>
      <div class="w-1/2 px-20 py-10">
        <div class="text-cl-3 text-lg">
          Os mais diversos temas da atualidade explicados por profissionais de
          referência em pequenos módulos de vídeo pensados para quem não tem
          tempo para os consumir.
        </div>
        <div class="flex flex-row items-center gap-x-6">
          <IconButton class="w-12 px-0"></IconButton>
          <span class="font-cl text-cl-2 text-lg font-thin"
            >Soluções personalizadas para empresas</span
          >
        </div>
      </div>
    </div>

    <div
      class="
        w-full
        flex flex-row
        text-cl-3 text-2xl
        px-20
        font-cl
        gap-x-10
        mt-8
      "
    >
      <div
        v-for="cat in categories"
        :key="cat"
        class="
          cursor-pointer
          hover:text-cl-2
          outline-none
          hover:border-cl-2
          border-transparent border-b-2
          hover:border-current
        "
        :class="{ 'text-cl-2 border-cl-2': selected == cat }"
        @click="selected = cat"
      >
        {{ cat }}
      </div>
    </div>
    <div class="w-full px-20 flex flex-row gap-x-4">
      <transition-group name="list-complete">
        <template v-for="row in filtered" :key="row._id">
          <Card class="list-complete-item" :fields="row"></Card>
        </template>
      </transition-group>
    </div>
  </section>
</template>

<script>
import Card from '../components/Card.vue'
import IconButton from '../components/IconButton.vue'
import axios from 'axios'
import { ref, computed, onMounted } from 'vue'

export default {
  components: { Card, IconButton },
  setup () {
    const selected = ref('All Categories')
    const categories = [
      'All Categories',
      'Blockchain',
      'Sustainability',
      'Emotions & Relationship'
    ]
    const cursos = ref([])
    const filtered = computed(() => cursos.value
      .filter(item => (selected.value != 'All Categories') ? item.category == selected.value.toLowerCase() : true)
    )
    async function getCursos () {
      const res = await axios.get('http://nhag.ga/cockpit/api/collections/get/Curso')
      console.log(res)
      cursos.value = res.data.entries
    }
    onMounted(getCursos)

    return { cursos, selected, categories, filtered }
  }

}
</script>

<style>
.list-complete-item {
  transition: all 0.8s ease;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
}
</style>