<template>
  <section class="fullpage">
    <div class="w-full h-full relative">
      <transition-group tag="div" :name="transitionName">
        <div
          :key="current"
          class="w-full h-screen"
          :class="slides[current].className"
        >
          <p>I'm {{ slides[current].className }}!</p>
        </div>
      </transition-group>
      <div class="btn btn-prev" @click="slide(-1)">&#10094;</div>
      <div class="btn btn-next" @click="slide(1)">&#10095;</div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
export default {
  setup () {

    const current = ref(0)
    const transitionName = ref("slide-next")

    const slides = [
      { className: "blue" },
      { className: "red" },
      { className: "yellow" }
    ]


    function slide (dir) {
      dir == 1
        ? transitionName.value = 'slide-next'
        : transitionName.value = 'slide-prev'
      const len = slides.length
      current.value = (current.value + dir % len + len) % len
    }

    return { current, slide, slides, transitionName }

  }
}
</script>

<style scoped>
/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

/* SLIDES CLASSES */

.blue {
  background: #4a69bd;
}

.red {
  background: #e55039;
}

.yellow {
  background: #f6b93b;
}

/* SLIDER STYLES */

.btn {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}
</style>