<template>
  <div class="bg-cl-1 h-32 flex flex-row items-center justify-between px-20">
    <Logo2></Logo2>
    <div class="text-white text-xs">C-LEARNIN' 2021, All Rights Reserved.</div>
  </div>
</template>

<script>
import Logo2 from '../components/Logo2.vue'
export default {
  components: { Logo2 }

}
</script>

<style>
</style>