<template>
  <section class="fullpage bgx bg-cover">
    <div class="w-full h-screen flex flex-col flex-wrap p-3">
      <div class="w-1/2 flex flex-col px-20 py-52">
        <div class="flex flex-row">
          <div
            class="
              bg-cl-1
              text-white
              h-10
              p-4
              py-6
              flex
              items-center
              font-cl font-bold
              text-2xl
            "
          >
            Martin Sousa Tavares
          </div>
        </div>
        <div
          class="
            mt-10
            font-cl font-bold
            text-8xl text-white
            uppercase
            leading-tight
          "
        >
          Orquestras do SÉC. XXI
        </div>
        <div class="mt-10 flex flex-row items-center gap-x-10">
          <IconButton class="w-20"></IconButton>
          <span class="text-white">Explore o Programa</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconButton from '../components/IconButton.vue'
export default {
  components: { IconButton }
}
</script>

<style scoped>
.bgx {
  background-image: url("~@/assets/background1.png");
}
</style>