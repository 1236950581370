<template>
  <svg
    width="160"
    height="24"
    viewBox="0 0 160 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.1245 14.1953V10.6297H30.2825V14.1953H21.1245Z" />
    <path
      d="M34.4309 23.3584V1.46353H38.5156V19.6033H48.4203V23.3584H34.4309Z"
    />
    <path
      d="M51.2048 23.3584V1.46353H65.1308V5.21859H55.2895V10.4402H64.2661V14.1953H55.2895V19.6033H65.3182V23.3584H51.2048Z"
    />
    <path
      d="M66.8633 23.3584L72.5534 1.46353H79.6705L85.3727 23.3584H81.1641L79.988 18.54H72.251L71.0719 23.3584H66.8633ZM73.2125 14.7269H79.0296L76.3992 4.0606H75.8429L73.2125 14.7269Z"
    />
    <path
      d="M87.9729 23.3584V1.46353H97.3818C98.6062 1.43521 99.8215 1.68147 100.94 2.18459C101.92 2.61812 102.747 3.33902 103.316 4.25378C103.885 5.16853 104.168 6.23493 104.13 7.31458V7.68733C104.13 9.00114 103.821 10.0654 103.202 10.8802C102.61 11.6706 101.818 12.2858 100.91 12.6615V13.2237C101.272 13.2235 101.63 13.3001 101.961 13.4485C102.291 13.5969 102.588 13.8139 102.83 14.0853C103.305 14.6809 103.547 15.4323 103.51 16.1966V23.3584H99.4227V16.7893C99.4436 16.3481 99.3061 15.9141 99.0357 15.5671C98.8694 15.3973 98.6679 15.267 98.4456 15.1856C98.2234 15.1042 97.986 15.0738 97.7507 15.0966H92.0576V23.3461L87.9729 23.3584ZM92.0606 11.3477H96.9495C97.3617 11.3723 97.7747 11.3145 98.1649 11.1777C98.555 11.0409 98.9146 10.8276 99.2231 10.5502C99.4988 10.2761 99.7144 9.94647 99.856 9.58279C99.9977 9.21911 100.062 8.82944 100.045 8.43896V8.13342C100.063 7.74423 99.9999 7.35564 99.8609 6.99224C99.722 6.62884 99.5099 6.29852 99.2382 6.02215C98.7031 5.49051 97.9381 5.2247 96.9495 5.2247H92.0576L92.0606 11.3477Z"
    />
    <path
      d="M107.531 23.3584V1.46353H115.299L119.601 20.5444H120.157V1.46353H124.182V23.3584H116.411L112.112 4.28058H111.556V23.3584H107.531Z"
    />
    <path d="M128.266 23.3584V1.46353H132.351V23.3584H128.266Z" />
    <path
      d="M136.435 23.3584V1.46353H144.209L148.511 20.5444H149.067V1.46353H153.091V23.3584H145.318L141.016 4.28058H140.459V23.3584H136.435Z"
    />
    <path d="M156.472 1.21603H160V10.4708H156.472V1.21603Z" />
    <path
      d="M9.20034 24.0031C6.37847 24.0031 4.13811 23.2056 2.47925 21.6107C0.820398 20.0158 -0.0090332 17.7354 -0.0090332 14.7697V9.23029C-0.0090332 6.2625 0.817375 3.98216 2.47018 2.38929C4.12299 0.796419 6.36335 0 9.19127 0C11.997 0 14.1658 0.775043 15.6977 2.32514C17.2296 3.87523 17.9965 6.00178 17.9985 8.70477V8.90336H13.7657V8.57339C13.8126 7.35142 13.4122 6.15484 12.641 5.21247C11.8912 4.33253 10.7443 3.89255 9.20034 3.89255C7.65637 3.89255 6.4601 4.36512 5.61152 5.31025C4.74078 6.25742 4.3054 7.54371 4.3054 9.16918V14.84C4.3054 16.445 4.74078 17.7253 5.61152 18.6806C6.48227 19.6359 7.67854 20.1156 9.20034 20.1197C10.7423 20.1197 11.8892 19.6746 12.641 18.7845C13.4117 17.8418 13.8121 16.6454 13.7657 15.4235V14.8308H17.9985V15.2922C17.9985 17.9972 17.2316 20.1237 15.6977 21.6718C14.1638 23.2199 11.998 23.9969 9.20034 24.0031Z"
    />
  </svg>
</template>

<script>
export default {

}
</script>

<style>
</style>