<template>
  <button class="px-20 text-sm bg-cl-1 text-white focus:outline-none uppercase">
    <slot></slot>
  </button>
</template>

<script>
export default {

}
</script>

<style>
</style>